*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {font-size: 100%;}

@media(max-width:700px) {
  html {font-size: 90%;}
}

@media(max-width:400px) {
  html {font-size: 80%;}
}

@media(min-width: 1700px) {
  html {font-size: 110%;}
}

@media(min-width: 3500px) {
  html {font-size: 200%;}
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Space Mono', monospace;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

p, a, q, small {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
}

h1 {font-size: 2.488em;}

h2 {font-size: 2.074em;}

h3 {font-size: 1.728em;}

h4 {font-size: 1.44em;}

h5 {font-size: 1.2em;}

small, .text_small {font-size: 0.833em;}

:root {
  --true-black: #0a0a0a;
  --extra-black: #111111;
  --black: #2a2a2a;
  --darker-white: #fafafaaa;
  --white: #fafafa;
  --extra-white: #ffffff;
  --menu-background: #333333fa;
  --dark-blue: #1373b7;
  --extra-dark-blue: #0e5e97;
  --light-blue: #2ea9dc;
  --menu-dark-blue: #1373b7aa;
}

html,
body,
#root {
  height: 100%;
  background: var(--extra-black);
}


/* 
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
